/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import "./layout.css"

import logoDiveIn from "../images/logo_dive_in.png"
import logoBonn from "../images/logo_bonn.png"
import logoStudioBnx from "../images/logo_studio_bnx.png"
import logoFringeEnsemble from "../images/logo_fringe_ensemble.png"

const Layout = ({ children }) => {
  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `var(--size-content)`,
          padding: `var(--size-gutter)`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `var(--space-5)`,
            fontSize: `var(--font-sm)`,
          }}
        >
          <div style={{ display: `flex`, justifyContent: `space-between`, flexWrap: `wrap` }}>
            <div style={{ width: `190px`, fontWeight: `bold` }}>
              studio_bnx<br/>
              Franziskanerstraße 3, Bonn<br/>
              Di-Sa, 11-18 Uhr
            </div>
            <img style={{ minWidth: `450px`, maxWidth: `450px` }} src={logoDiveIn} alt="Logo Dive In" />
            <img style={{ minWidth: `120px`, maxWidth: `120px` }} src={logoBonn} alt="Logo Bonn" />
            <a href='https://www.instagram.com/studio_bnx/' target="_blank"><img style={{ minWidth: `240px`, maxWidth: `240px` }} src={logoStudioBnx} alt="Logo Studio Bnx" /></a>
            <a href='https://www.fringe-ensemble.de/' target="_blank"><img style={{ minWidth: `180px`, maxWidth: `180px` }} src={logoFringeEnsemble} alt="Logo Fringe Ensemble" /></a>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
