import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/splashLayout"
import Seo from "../components/seo"
// import { StaticImage } from "gatsby-plugin-image"

// images
import logoCitystories from "../images/logo_citystories_bnx_crop.png"

const DownloadPage = () => (
  <Layout>
    <Seo title="City Stories" />
    <div style={{ display: `flex`, justifyContent: `space-around`, paddingTop: `80px`, paddingBottom: `120px` }}>
      <div style={{ width: `65%` }}>
        <div>
          <img style={{ maxWidth: `75%` }} src={logoCitystories} alt="City Stories" />
        </div>
        <h2 style={{ marginBottom: `40px`, borderBottom: `6px dotted black` }}>Die City Stories App gibt es hier ab 05.09.2022.</h2>
        <div style={{ fontSize: `var(--font-sm)`,fontWeight: `bold` }}>Ein Team des fringe ensemble war im Frühjahr dieses Jahres in Bonn unterwegs und hat Bürger*innen gebeten, spontan eine persönliche Geschichte für das Projekt „City Stories“ zu stiften. Jede ist mit einem ganz bestimmten Ort verknüpft. Aus der großen Zahl wurden 50 Geschichten ausgewählt, die Du mit einer für das Smartphone entwickelten App an den jeweiligen Orten anhören können – gesprochen von Schauspieler*innen.</div>
      </div>
      <div style={{ width: `22%` }}></div>
    </div>
  </Layout>
)

export default DownloadPage
